import mixpanel, { RequestOptions, Callback } from "mixpanel-browser";
import events from "./events/events.json";
import { Money } from "@nilo/codegen";
import { fomattedMoney } from "../components";
import { getPathProperty } from "./utils";

const analytics = (
  eventName: string,
  properties?: Record<string, string>,
  optionsOrCallback?: RequestOptions | Callback,
  callback?: Callback
) => {
  if (!mixpanel) return;

  if (properties) {
    return mixpanel.track(eventName, properties, optionsOrCallback, callback);
  }

  return mixpanel.track(eventName);
};

export const identifyUser = (uid: string) => {
  return mixpanel.identify(uid);
};

export const resetUser = () => {
  return mixpanel.reset();
};

export const setUserProperties = ({ user, profile, subdomain }) => {
  const internalCode = profile?.store?.internalCode;
  const { source, name, username } = user;

  return mixpanel.people.set({
    [events.properties.source]: source || "",
    [events.properties.name]: name || "",
    [events.properties.subdomain]: subdomain || "",
    [events.properties.username]: username || "",
    [events.properties.internalCode]: internalCode || ""
  });
};

export const trackCategories = {
  auth: {
    clickOnRegister: () => analytics(events.register.clickOnRegister),
    clickOnLogin: () => analytics(events.login.clickOnLogin)
  },
  register: {
    organicRegistration: () =>
      analytics(events.register.completeOrganicRegistration)
  },
  login: {
    submitUsername: (channel: string) =>
      analytics(events.login.submitUsername, {
        [events.login.channel]: channel
      }),
    submitPassword: () => analytics(events.login.submitPassword),
    requestResetPassword: () => analytics(events.login.submitPassword),
    confirmResetPassword: () => analytics(events.login.submitPassword)
  },
  shopping: {
    goToShop: () => analytics(events.shopping.goToShop),
    filterByCategory: (category: string) =>
      analytics(events.shopping.category.filterByCategory, {
        [events.shopping.category.categoryProperty]: category
      }),
    filterByBrand: (brand: string) =>
      analytics(events.shopping.brands.filterByBrand, {
        [events.shopping.brands.brandProperty]: brand
      }),
    removeCategoryFilter: () =>
      analytics(events.shopping.products.removeCategoryFilter),
    removeBrandFilter: () =>
      analytics(events.shopping.products.removeBrandFilter)
  },
  products: {
    selectProductDisplay: (displaySelected: string) =>
      analytics(events.shopping.products.selectDisplay, {
        [events.shopping.products.displaySelectedProperty]: displaySelected
      }),
    addToCart: (path?: string) =>
      analytics(events.shopping.products.addToCart, {
        [events.shopping.products.pathProperty]: getPathProperty(path)
      }),
    decreaseQuantity: (cartTotal: Money) => {
      const formatted = fomattedMoney(cartTotal) || "0";

      return analytics(events.shopping.products.decreaseQuantity, {
        [events.shopping.products.totalToReachProperty]: formatted
      });
    },
    increaseQuantity: () =>
      analytics(events.shopping.products.increaseQuantity),
    typeQuantity: () => analytics(events.shopping.products.typeQuantity),
    enterDetail: (promotion: boolean) =>
      analytics(events.shopping.products.enterProductDetail, {
        [events.properties.promotion]: promotion ? "true" : "false"
      }),
    seeProductsInPromotion: () =>
      analytics(events.shopping.products.seeProductsInPromotion),
    notifyBackInStock: () =>
      analytics(events.shopping.products.notifyBackInStock)
  },
  cart: {
    goToCart: (cartTotalItems: string, totalToReach: Money) => {
      const cartTotal = totalToReach ? fomattedMoney(totalToReach) : "0";

      return analytics(events.cart.clickOnCart, {
        [events.shopping.header.products]: cartTotalItems,
        [events.shopping.header.totalToReachProperty]: cartTotal
      });
    },
    confimDeleteProduct: () => analytics(events.cart.confirmDeleteProduct),
    cancelDeleteProduct: () => analytics(events.cart.cancelDeleteProduct),
    deleteProduct: () => analytics(events.cart.deleteProduct)
  },
  promos: {
    promos: () => analytics(events.promos.clickOnPromos),
    selectPromoType: (promoType: string) =>
      analytics(events.promos.selectPromoType, {
        [events.promos.promoTypeProperty]: promoType
      }),
    seePromoRequirements: () => analytics(events.promos.seePromoRequirements),
    addPromoQuantity: () => analytics(events.promos.addPromoQuantity)
  },
  orders: {
    goToOrders: () => analytics(events.orders.goToOrders),
    orderDetails: (orderState: string) =>
      analytics(events.orders.orderDetails, {
        [events.orders.orderStateProperty]: orderState
      }),
    requestCancelOrder: () => analytics(events.orders.requestCancelOrder),
    confirmCancelOrder: () => analytics(events.orders.confirmCancelOrder),
    filterByOrderState: (orderState: string) =>
      analytics(events.orders.filterByOrderState, {
        [events.orders.orderStateProperty]: orderState
      }),
    repeatOrder: () => analytics(events.orders.repeatOrder)
  },
  checkout: {
    goToCheckout: () => analytics(events.checkout.goToCheckout),
    openPaymentMethod: () => analytics(events.checkout.openPaymentMethod),
    selectPaymentMethod: (paymentMethod: string) =>
      analytics(events.checkout.selectPaymentMethod, {
        [events.checkout.selectedPaymentMethodProperty]: paymentMethod
      }),
    completeOrder: () => analytics(events.checkout.completeOrder),
    goToWhatsapp: () => analytics(events.checkout.goToWhatsapp),
    backToStore: () => analytics(events.checkout.backToStore)
  },
  suggestions: {
    goToSuggestions: () => analytics(events.suggestions.clickOnSuggestion)
  },
  chatbot: {
    authFromChatBot: () => analytics(events.chatbot.authFromChatbot),
    goToOrders: () => analytics(events.chatbot.toOrders),
    goToOrderId: (orderId: string) =>
      analytics(events.chatbot.toOrderId, {
        [events.chatbot.orderIdProperty]: orderId
      }),
    goToProduct: (productId: string) =>
      analytics(events.chatbot.toProduct, {
        [events.chatbot.productIdProperty]: productId
      }),
    fromCampaign: (campaignId: string) =>
      analytics(events.chatbot.fromCampaign, {
        [events.chatbot.campaignIdProperty]: campaignId
      }),
    redirectTo: {
      [events.qp.orders]: (orderId: string) =>
        !!orderId
          ? analytics(events.chatbot.toOrderId, {
              [events.chatbot.orderIdProperty]: orderId
            })
          : analytics(events.chatbot.toOrders),
      [events.qp.product]: (productId: string) =>
        analytics(events.chatbot.toProduct, {
          [events.chatbot.productIdProperty]: productId
        }),
      [events.qp.shopping]: () => analytics(events.chatbot.toShopping)
    }
  }
};
