"use client";

import { NiloButton } from "@nilo/design";
import Link from "next/link";
import Header from "src/app/[locale]/components/ui/header";
import { useSite } from "src/app/hooks/use-site";
import Image from "next/image";
import { addImagePath } from "@utils";
import { useTranslations } from "next-intl";
import { urlWithLocale } from "@i18n";
import { useAnalytics } from "@analytics";
import { useMediaQuery } from "react-responsive";

export const Onboarding = () => {
  const { site } = useSite();
  const t = useTranslations("onboarding");
  const { track } = useAnalytics("auth");

  const handleClick = (register?: "login" | "register") => {
    register === "register" ? track.clickOnRegister() : track.clickOnLogin();
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const backgroundImage = isMobile
    ? site?.backgroundImage
    : site?.backgroundImageDesktop || site?.backgroundImage;

  return (
    <>
      <title>{site?.onboardingTitle}</title>
      <Header />
      <div className="relative flex items-center justify-center text-center">
        <div className="absolute top-[-15px] !h-[calc(100dvh-73px)] h-[calc(100vh-73px)] w-full md:overflow-hidden">
          {!!backgroundImage && (
            <Image
              alt="background"
              className="rounded-t-2xl bg-center object-cover"
              fill
              priority={true}
              sizes="100vw"
              src={addImagePath(backgroundImage)}
            />
          )}
        </div>
        <div className="absolute top-40 mx-4">
          <div className="text-white">
            <p className="text-2xl font-bold">
              {t("welcome")} {site?.name}!
            </p>
            <p className="mx-8 mt-3 text-xs">{site?.onboardingDescription}</p>
          </div>

          <div className="fixed bottom-0 left-0 right-0 items-center justify-center p-4 md:relative md:mt-20">
            <Link
              className="block"
              href={urlWithLocale("login")}
              onClick={() => handleClick("login")}
            >
              <NiloButton className="mb-3 w-full max-w-[360px]">
                {t("login")}
              </NiloButton>
            </Link>

            <Link
              className="block"
              href={urlWithLocale("register")}
              id="mixpanel_register"
              onClick={() => handleClick("register")}
            >
              <NiloButton className="w-full max-w-[360px]" variant="secondary">
                {t("register")}
              </NiloButton>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
